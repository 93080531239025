<template>
  <footer>
    <div class="holder" data-aos="zoom-in-up">
      <img
        src="@/assets/logos/cpkc-stadium-stacked-wordmark.svg"
        alt=""
        data-aos="zoom-in-up"
        class="shield"
      />
      <div class="social" data-aos="zoom-in-up">
        <h3>Follow us</h3>
        <a target="_blank" href="https://twitter.com/cpkcstadium"
          ><img src="@/assets/logos/x.svg" alt="" />
        </a>
        <a target="_blank" href="https://instagram.com/cpkcstadium"
          ><img src="@/assets/logos/instagram.svg" alt="" />
        </a>
        <a target="_blank" href="https://facebook.com/cpkcstadium"
          ><img src="@/assets/logos/facebook.svg" alt=""
        /></a>
        <a target="_blank" href="https://youtube.com/kccurrent"
          ><img src="@/assets/logos/youtube.svg" alt=""
        /></a>
        <a target="_blank" href="https://www.linkedin.com/company/cpkcstadium/"
          ><img src="@/assets/logos/linkedin.svg" alt=""
        /></a>
      </div>
    </div>
    <div class="legal">
      <p>
        <router-link :to="{ name: 'Privacy Policy' }"
          >Privacy Policy</router-link
        >
        <span>&bull;</span>
        <router-link :to="{ name: 'Terms Conditions' }"
          >Terms & Conditions</router-link
        >
      </p>
      <p>© {{ new Date().getFullYear() }}, KC WFC, LLC. All Rights Reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
  background: url("~@/assets/images/water.jpg");
  background-size: cover;
  display: grid;
  place-items: center;
  padding: 50px;
  padding-bottom: 100px;

  p {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: var(--white);
  }
  > div {
    width: 100%;
    max-width: 1440px;
  }
}
h3 {
  color: var(--white);
  text-transform: uppercase;
  margin: 0;
}
a {
  color: var(--white);
}
.holder {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  @media (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: 1200px) {
    gap: 160px;
  }
}
.shield {
  max-width: 86px;
}
.social {
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  gap: 15px;
  @media (max-width: 499px) {
    flex-wrap: wrap;
    justify-content: center;
    h3 {
      width: 100%;
    }
  }
  p {
    margin: 0;
  }
  div {
    text-align: left;
  }
  a {
    display: flex;
    gap: 15px;
    align-items: center;
    color: var(--white);
    @media (hover: hover) {
      transition: opacity 0.2s ease-in-out;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
.legal {
  display: block;
  justify-content: space-between;
  border-top: 1px solid rgba(#ffffff, 0.08);
  padding-top: 30px;
  margin-top: 10px;
  @media (min-width: 800px) {
    display: flex;
  }
  span {
    margin: 0 5px;
  }
  a,
  p {
    font-size: 0.75rem;
    margin: 0;
    opacity: 0.6;
  }
}
</style>