<template>
  <div class="sliding-gallery" :class="{'in-modal':inModal}">
    <div class="images">
      <div
        v-for="(image, id) in images"
        class="image"
        :class="{ active: current == id }"
        @click="current = id"
      >
        <div>
          <img
            v-if="image.indexOf('svg') >= 0"
            v-svg-inline
            :src="require(`@/assets/${image}`)"
            alt=""
            @load="loaded(id, image)"
            :class="`i${id}`"
          />
          <img v-else :src="require(`@/assets/${image}`)" alt="" />
        </div>
      </div>
    </div>
    <img class='gradient' style="left: 0" src="@/assets/images/gradient-left.png" alt="" />
    <img class='gradient' style="right: 0" src="@/assets/images/gradient-right.png" alt="" />
    <div class="ui">
      <button
        v-if="images.length > 1"
        @click="
          current--;
          current < 0 ? (current = images.length - 1) : '';
        "
      >
        <img v-svg-inline src="@/assets/icons/carousel-arrow.svg" alt="" />
      </button>
      <button
        v-if="images.length > 1"
        class="next"
        @click="
          current++;
          current == images.length ? (current = 0) : '';
        "
      >
        <img v-svg-inline src="@/assets/icons/carousel-arrow.svg" alt="" />
      </button>
      <transition name="photo-fade" mode="out-in" appear="">
        <div
          class="photo-info desktop"
          v-if="$store.state.photoInfo[images[current]]"
          :key="current"
        >
          <p class="title">
            {{ $store.state.photoInfo[images[current]].title }}
          </p>
          <p class="description">
            {{ $store.state.photoInfo[images[current]].description }}
          </p>
        </div>
      </transition>
    </div>
     
 <div
          class="photo-info mobile"
          v-if="$store.state.photoInfo[images[current]]"
          :key="current"
        >
          <p class="title">
            {{ $store.state.photoInfo[images[current]].title }}
          </p>
          <p class="description">
            {{ $store.state.photoInfo[images[current]].description }}
          </p>
        </div>
      
  </div>
         
</template>

<script>
export default {
  name: "FullWidthSlidingGallery",
  props: {
    inModal:Boolean,
    images: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  mounted() {
    const images = this.$el.querySelector(".images");
    const element = this.$el.querySelector(".image.active");
    if (element) {
      images.scrollLeft = element.offsetLeft;
    }
  },
  methods: {
    loaded(image, file) {
      let i = this.$el.querySelector(`.i${image} image`);
      i.setAttribute("href", require(`@/assets/${file.replace("svg", "png")}`));
    },
  },
  watch: {
    "$store.state.modalInitialImage"(value) {
      if(this.inModal) {
        this.current = value;
      }
    },
    current() {
      this.$nextTick(() => {
        const images = this.$el.querySelector(".images");
        const element = this.$el.querySelector(".image.active");
        images.scrollLeft = element.offsetLeft;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.photo-fade-enter-active,
.photo-fade-leave-active {
  transition: all 1s ease;
}
.photo-fade-enter-from,
.photo-fade-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
@media (max-width:900px) {
 .gradient {
  display:none;
} 
}
.photo-info {
  position: absolute;
  &.desktop {
    display:none;
    @media (min-width:600px) {
      display: flex;
    }
  }
  &.mobile {
    display: flex;
    position: relative;
    flex-direction: column;
    text-align-last: center;
    height:220px;
    .description {
      text-align: center;
    }
    @media (min-width:599px) {
      display:none;
    }
  }
  bottom: 0;
  left: 0;
  padding: 50px;  
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 100px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    content: "";
  }
  p {
    position: relative;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding: 0;
    max-width: unset;
    font-size: 1rem;
  }
  .title {
    font-family: "Alternate Gothic No3 D";
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.25rem;
    line-height: 1;
  }
}
svg {
  :deep() {
    image {
      pointer-events: none;
    }
    > g {
      cursor: pointer;
      filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.7));
      > g:last-child {
        opacity: 0;
        transition: all 0.2s ease;
        pointer-events: none;
      }
      &:hover {
        > g:last-child {
          opacity: 1;
        }
      }
    }
  }
}
.ui {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  button {
    pointer-events: all;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    cursor: pointer;
    img,
    svg {
      background: var(--red);
      padding: 10px;
      outline: none;
    }
    &.next {
      img,
      svg {
        transform: scaleX(-1);
      }
    }
    @media (hover: hover) {
      img,
      svg {
        transition: all 0.2s ease-in-out;
        :deep() {
          path {
            transition: all 0.2s ease-in-out;
          }
        }
      }
      &:hover {
        img,
        svg {
          background: var(--teal);
          :deep() {
            path {
              fill: var(--blue);
            }
          }
        }
      }
    }
  }
}
.sliding-gallery {
  position: relative;
  overflow: hidden;
  > img {
    position: absolute;
    top: 0;
    pointer-events: none;
    height:100%;
  }
}
.images {
  padding: 0;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  gap: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  height: auto;
}
.image {
  display: flex;
  align-items: center;
  position: relative;
  > div {
    max-height: 80vh;
    width: 100vw;
  }
  img,
  svg {
    max-height: 80vh;
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
}
.in-modal {
  .image > div {
   height: calc(100vh - 50px); 
  }
}
</style>