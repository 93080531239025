<template>
  <div id="stay-connected">
    <div class="fan">
      <img src="@/assets/images/stay-connected.png" alt="" />
    </div>
    <div data-aos="zoom-in-up" class="stay-connected">
      <div>
        <h2>Stay Connected</h2>
        <p style='max-width:600px;'>
          {{ $store.state.emailSubmitted ? response : message }}
        </p>
        <StayConnectedForm/>
      </div>
    </div>
  </div>
</template>

<script>
import StayConnectedForm from "@/components/StayConnectedForm";
export default {
  name: "StayConnected",
  components: {
    StayConnectedForm
  },
  data() {
    return {
      message:
        "Stay up-to-date with CPKC Stadium news, content and seat selection information.",
        response:"Thanks for subscribing! Be on the lookout for CPKC Stadium news and content!",        
    };
  },  
};
</script>

<style lang="scss" scoped>
.stay-connected {
  display: flex;
  align-items: center;  
}
.fan {
  img {
    width: 100vw;
    max-width: 550px;
    position: relative;
    vertical-align: bottom;
    &.background {
      position: absolute;
      display: none;
      @media (min-width: 900px) {
        display: inline;
        bottom: 0;
        top: unset;
      }
    }
  }
}
#stay-connected {
  position: relative;
  display: flex;
  text-align: left;
  align-items: center;
  gap: 25px;
  flex-direction: column-reverse;
  padding: 50px;
  padding-bottom: 0;
  overflow: hidden;
  @media (min-width: 600px) {
    padding-bottom: 50px;
    margin-left: 50px;
    padding: 0;
    padding-right: 50px;
    flex-direction: row;
    align-items: stretch;
    .fan img {
      width: 40vw;
    }
  }
  @media (min-width: 1000px) {
    gap: 115px;
  }
  &:before {
    content: "";
    background-color: var(--teal);
    background-image: url("~@/assets/images/stay-connected-pattern.png");
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
h2 {
  margin-top: 0;
}
h2,
p {
  color: var(--blue);
}
</style>