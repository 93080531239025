<template>
  <div class="form-field" :class="{ alternate: alternate }">
    <label :for="id">{{ label }}</label>
    <div class="fields-holder">
      <div>
        <input :type="type" :name="id" :id="id" placeholder="XXX" />
        <input
          :type="type"
          :name="`${id}-1`"
          :id="`${id}-1`"
          placeholder="XXX"
        />
        <input
          :type="type"
          :name="`${id}-2`"
          :id="`${id}-2`"
          placeholder="XXXX"
        />
      </div>
      <img v-if="icon !== ''" :src="require(`@/assets/icons/${icon}`)" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormField",
  props: {
    id: String,
    type: String,
    label: String,
    placeholder: String,
    alternate: Boolean,
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.form-field {
  position: relative;
  margin-bottom: 15px;
  text-align: left;
  &.alternate label {
    color: var(--white);
  }
  label {
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: 800;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    opacity: 0.9;
    font-size: 0.625rem;
    text-align: left;
    padding-left: 24px;
  }
  .fields-holder {
    box-shadow: inset 0px 2px 4px rgb(0 0 0 / 25%);
    border-radius: 500px;
    border: none;
    padding: 13px 24px;
    width: 100%;
    box-sizing: border-box;
    background: var(--white);
    margin-top: 16px;
    div {
      display: flex;
      justify-content: space-evenly;
      max-width: 375px;
      width: calc(100% - 45px);
      input {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 1rem;
        border: none;
        background: none;
        flex-grow: 1;
        width: calc(100% / 3);
        color: var(--blue);
        &::placeholder {
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 1rem;
        }
      }
    }
  }
  div {
    position: relative;
    input {
      border: none;
      color: var(--blue);
    }
    img {
      position: absolute;
      right: 24px;
      top: 10px;
    }
  }
}
</style>