<template>
  <div class="form-field">
    <label :for="id">{{ label }}</label>
    <div>
      <select :name="id" :id="id">
        <option v-for="option in options" :value="option">{{option}}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormSelectField",
  props: {
    id: String,
    label: String,
    placeholder: String,
    options: {
      type:Array,
      default:[]
    }
  },
};
</script>

<style lang="scss" scoped>
.form-field {
  position: relative;
  margin-bottom: 15px;
  text-align: left;
  label {
    color: var(--white);
    text-align: left;
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: 800;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    opacity: 0.9;
    font-size: 0.625rem;
    text-align: left;
    padding-left: 24px;
  }
  div {    
    position: relative;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 500px;
    background: var(--white);
    overflow: hidden;
    padding: 13px 24px;
    margin-top:16px;
    select {
      border: none;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 1rem;
      width: 100%;
      box-sizing: border-box;
      color: var(--blue);
    }
  }
}
</style>