<template>
  <form
    v-if="!$store.state.emailSubmitted"
    ref="form"
    id="form45"
    name="form45"
    accept-charset="UTF-8"
    autocomplete="off"
    enctype="multipart/form-data"
    method="post"
    novalidate
    action="https://kcwoso.wufoo.com/forms/r1fgncqa1i742fa/#public"
    @submit.prevent="submit"
  >
    <FormField
      icon="email.svg"
      type="email"
      id="Field1"
      label=""
      placeholder="Email Address"
      :small="inline"
    />
    <div style="display: none">
      <label for="comment">Do Not Fill This Out</label>
      <textarea name="comment" id="comment" rows="1" cols="1"></textarea>
      <input
        type="hidden"
        id="idstamp"
        name="idstamp"
        value="2+NmYKe3zB8chBItBQGbRsEpzkygE0ZUUFjmYID9mjc="
      />
      <input
        type="hidden"
        id="encryptedPassword"
        name="encryptedPassword"
        value=""
      />
    </div>
    <RoundedButton text="Sign Up" icon="circle-arrow.svg" :small="inline"/>
  </form>
</template>

<script>
import axios from 'axios';
import RoundedButton from "@/components/RoundedButton";
import FormField from "@/components/FormField";
export default {
    name: "StayConnectedForm",
    components: {
    RoundedButton,
    FormField,
  },
  props: {
    inline:Boolean
  },
  data() {
    return {
      testEmail: /^.+@.+\..+$/,
    };
  },
  methods: {
    submit(e) {
      const formData = new FormData(e.target);
      const validateData = [];
      formData.forEach((value, key) => (validateData[key] = value));
      this.formErrors = [];
      if (validateData.Field1 === "" || !this.testEmail.test(validateData.Field1)) {
        this.formErrors.push("Email Address");
      }
      if (this.formErrors.length > 0) {
        alert("Please enter your email address.");
        return;
      }
      axios
        .post(e.target.action, formData)
        .then((res) => {
          //console.log(res);
          this.$store.commit('setEmailSubmitted');
          this.$gtag.event("generate_lead", {});
        })
        .catch((error) => {
          // error.response.status Check status code
          alert("An error has occurred. Please try again later.");
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>