<template>
  <div class="home">
    <LandingSection background="landing.jpg" noSubHeading addShadow>
      <img
        src="@/assets/logos/cpkc-stadium-stacked-wordmark.svg"
        alt=""
        width="200"
        class="shield"
      />
      <h1 class="italic special-h1" >The first women’s professional sports stadium <span>in the world.</span></h1>      
    </LandingSection>
    <Promos />
    <TicketInterestSection />
    <StadiumSection @pause="$emit('pause');"/>
    <SeatingSection @pause="$emit('pause');"/>    
    <StayConnectedSection />
    <Modal2 v-show="showAnswerModal" @close="showAnswerModal = false;" class="center-content">
    <template v-slot:content>
      <div class='modal-answer'>
        <div>
          <img src="@/assets/images/digital-billboard.jpg" alt="" class="desktop">
          <img src="@/assets/images/digital-billboard-portrait.jpg" alt="" class="mobile">
         </div>
      </div>
    </template>
  </Modal2>
  </div>
</template>

<script>
//
import ImageButton from "@/components/ImageButton";
import Modal2 from "@/components/Modal2";
import RoundedButton from "@/components/RoundedButton";
import LandingSection from "@/sections/LandingSection";
import SeatingSection from "@/sections/SeatingSection";
import StadiumSection from "@/sections/StadiumSection";
import StayConnectedSection from "@/sections/StayConnectedSection";
import TicketInterestSection from "@/sections/TicketInterestSection";
import Promos from "@/sections/Promos";
export default {
  name: "Home",
  components: {
    Modal2,
    RoundedButton,
    ImageButton,
    LandingSection,
    StadiumSection,
    StayConnectedSection,
    TicketInterestSection,
    SeatingSection,
    Promos,
  },
  props : {
    buynow:Boolean
  },
  data() {
    return {
      showAnswerModal:false
    }
  },
  mounted() {
    if(this.buynow) {
      document.querySelector('.fevo-button').click()
    }
  }
};
</script>

<style lang="scss" scoped>

@media (max-width:699px) {
  .desktop {
    display:none;
  }
}
@media (min-width:700px) {
  .mobile {
    display: none;
  }

}
.modal-answer {
  display:grid;
  place-items: center;
  > div {
  max-width:1200px;
  font-family: "Montserrat";
  font-weight: 400;
  color:var(--white);
  text-align:left;
  margin:0 auto;
  padding:25px;  
  img {
    width: 80vw;
    max-width:1200px;    
  }
  }
}
.special-h1 {
  font-size: 2.4rem !important;
}
  .italic {
    max-width:550px;
  }
  .no-sub-heading h1.italic {
    @media (max-width:490px) {
      font-size: 2.5rem;
    }
  }
</style>