import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import VueGtag from "vue-gtag";
import AOS from 'aos'
import 'aos/dist/aos.css'
//AOS.init({ disable: 'mobile' });

const app = createApp(App);
app.use(VueSvgInlinePlugin);
app.use(store).use(router).use(AOS);
app.use(VueGtag, {
    disableScriptLoad: true,
    config: { id: "G-8NPYT1V77Y" }
}, router);


store.dispatch("fetchGalleries");        
store.dispatch("fetchNews");        
store.dispatch("fetchCareers");        

router.isReady().then(() => {
    app.mount('#app');
})
