<template>
  <div class="form-field" :class="{ alternate: alternate, 'no-label':small }">
    <label v-if="!small" :for="id">{{ label }}</label>
    <div>
      <input :value="modelValue" @input="$emit('update:modelValue', $event.target.value);" :type="type" :name="id" :id="id" :placeholder="placeholder" />
      <img v-if="icon !== ''" :src="require(`@/assets/icons/${icon}`)" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormField",
  inheritAttrs: false,
  props: {
    id: String,
    type: String,
    label: String,
    placeholder: String,
    alternate: Boolean,
    small:Boolean,
    modelValue : {
      required:false
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.form-field {
  position: relative;
  margin-bottom: 15px;
  text-align: left;  
  &.alternate label {
    color: var(--white);
  }
  label {
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: 800;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    opacity: 0.9;
    font-size: 0.625rem;
    text-align: left;
    padding-left: 24px;
  }
  div {
    padding-top: 16px;
    position: relative;
    input {
      box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 500px;
      border: none;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 1rem;
      padding: 13px 24px;
      width: 100%;
      box-sizing: border-box;
      color: var(--blue);
      overflow:hidden;
      &::placeholder {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 1rem;
      }
    }
    img {
      position: absolute;
      right: 24px;
      top: 27px;
      z-index: 1;
    }
  }
  &.no-label {    
    margin-bottom:0;
    > div {    
      display:flex;
      gap:10px;
      padding-top:0;
      position: relative;      
        &:after {
          //content:"";
          position: absolute;
          top:5px;
          right:8px;
          background:white;
          width:50px;
          height:calc(100% - 10px);
        }
      input {
        padding: 6px 16px;        
      }      
      img {
        top:4px;
        background:var(--white);        
      }
      &:focus-within {
        img {
          display:none;          
        }
      }
    }
  }
}
</style>