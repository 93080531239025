<template>
  <a target="_blank" href="https://www.kansascitycurrent.com/tickets"><span>Buy Tickets</span></a>
</template>

<style lang="scss" scoped>
a, button {
  background: var(--red);
  color: var(--white);
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  font-size: 1rem;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  overflow: hidden;
  position: relative;
  height: 70px;
  box-sizing: border-box;
  display: grid;
  place-items: center;
  cursor: pointer;
  span {
    position: relative;
    vertical-align: middle;
  }
  &:after {
    content: "";
    animation: float 6s linear infinite;
    background:url('~@/assets/images/pattern.png');    
    width: 100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
  }
  @media (hover: hover) {
    transition: all 0.2s ease-in-out;
    &:before {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      inset: 0 0 0 0;
      background: var(--teal);
      //z-index: -1;
      transition: transform 0.3s ease;

      transform: scaleX(0);
      transform-origin: bottom right;
    }
    &:hover:before {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
 @keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
    opacity: 0;    
	}
	50% {
    opacity: 0.1;    
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-10px);
	}
	100% {
    opacity: 0;    
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}
}
</style>