<template>
  <div
    id="stadium"
    :style="`background-image:url('${require('@/assets/backgrounds/stadium.png')}')`"
  >
    <div class="flex">
      <div>
        <h2 data-aos="zoom-in-up">
          <span class="teal">In the Soccer capital of America</span> We Are
          Building the World’s Best Women’s Soccer Club
        </h2>
        <RoundedButton
          data-aos="zoom-in-up"
          alternate
          text="learn more about the stadium"
          link="Stadium"
          @click="$emit('pause');"
        />
      </div>
      <div>
        <p data-aos="zoom-in-up" class="teal">
          A world-class stadium for our players.<br />A world-class experience
          for our fans.
        </p>
        <p data-aos="zoom-in-up">
          In 2024, Kansas City Current will open CPKC Stadium at Berkley
          Riverfront Park, in one of the boldest builds ever seen in Kansas City
          and women’s sports at large.
        </p>
      </div>
    </div>
    <div class="has-current">
      <FullWidthSlidingGallery :images="$store.state.homepageStadiumRenderings" style="z-index:1;" />
      <img src="@/assets/images/pattern-current.png" alt="" class="current" />
    </div>
  </div>
</template>

<script>
import ImageButton from "@/components/ImageButton";
import RoundedButton from "@/components/RoundedButton";
import FullWidthSlidingGallery from "@/components/FullWidthSlidingGallery";
export default {
  name: "StadiumSection",
  components: {
    ImageButton,
    RoundedButton,
    FullWidthSlidingGallery,
  },  
};
</script>

<style lang="scss" scoped>
.sliding-gallery {
  margin-bottom: 120px;
}
.has-current {
  position: relative;    
  .current {
    position: absolute;
    right: 0;
    bottom: 0px;
    @media (max-width:600px) {
      display:none;
    }
    @media (max-width:800px) {
      opacity: 0.5;
    }
  }
}
#stadium {
  background-size: cover;
  background-position: top center;
  position: relative;
  overflow: hidden;
  padding:100px 0;
  padding-bottom: 0;
  p {
    font-family: "Montserrat";
    font-weight: 500;
    &.teal {
      font-weight: 700;
    }
  }
  .flex {
    position: relative;
    display: flex;
    gap: 85px;
    padding: 50px;
    max-width: 1440px;
    margin: 0 auto;
    text-align: left;
    flex-direction: column;    
    z-index: 1;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        var(--blue) 0%,
        rgba(11, 43, 61, 1) 100%
      );      
      opacity: 0.5;
      pointer-events: none;
      @media (min-width: 600px) {
        opacity: 0;
      }
    }
    @media (min-width: 800px) {
      flex-direction: row;
    }
  }
  h2 {
    font-family: "Alternate Gothic No3 D";
    max-width: 700px;
  }
}
</style>