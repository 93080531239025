<template>
  <section
    class="landing"
    :class="{
      'top-align': topAlign,
      shadow: addShadow,
      'no-sub-heading': noSubHeading,
    }"
    :style="getBackground()"
  >
    <div data-aos="zoom-in-up">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: "LandingSection",
  props: {
    background: {
      type: String,
      default: "",
    },
    topAlign: Boolean,
    noSubHeading: Boolean,
    addShadow: Boolean,
  },
  components: {},
  methods: {
    getBackground() {
      if (this.background !== "") {
        return `background-image:url(${require(`@/assets/backgrounds/${this.background}`)}`;
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() h2 {
  font-family: "Custom Current Type";
}
.no-sub-heading {
  :deep() {
    h1 {
      font-family: "Custom Current Type";
      line-height: 0.88;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 3.75rem;
      margin: 20px 0;
      color: var(--white);
      margin-bottom: 24px;
      letter-spacing: 0em;
      &.italic {
        font-size: 4.25rem;
      }      
      span {
        background:var(--red);
        padding:0 16px;
      }
    }
  }
}
section {
  margin: 0;
  padding: 0;
  min-height: calc(100vh - 72px);
  height: calc(100vh + 0px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: grid;
  place-items: center;
  position: relative;
  box-sizing: border-box;
  &.top-align {
    align-items: start;
    padding: 112px 0;
  }
  > div {
    position: relative;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &.shadow:before {
    content: "";
    background-color:#081F2C;
    opacity: 0.6;
  }
}
</style>