<template>
  <div id="ticket-interest">
    <div data-aos="zoom-in-up">
      <h2>Interested in CPKC Stadium Tickets?</h2>
      <div class="success" v-if="success">
        <p class="teal">Thanks for your submission!</p>
      </div>
      <p class="teal" v-if="!success">Fill out this form to get in touch about 2024 KC Current Season Tickets.</p>
      <form
        v-if="!success"
        ref="form"
        id="form36"
        name="form36"
        accept-charset="UTF-8"
        autocomplete="off"
        enctype="multipart/form-data"
        method="post"
        novalidate
        action="https://kcwoso.wufoo.com/forms/q10os8qv1qwpktf/#public"
        @submit.prevent="submit"
      >
        <div class="fields">
          <FormField
            alternate
            icon="name.svg"
            type="text"
            id="Field3"
            label="First Name*"
            placeholder="First Name"
            v-model="firstName"
          />
          <FormField
            alternate
            icon="name.svg"
            type="text"
            id="Field4"
            label="Last Name*"
            placeholder="Last Name"
          />
          <FormField
            alternate
            icon="email.svg"
            type="email"
            id="Field1"
            label="Email Address*"
            placeholder="Email Address"
          />
          <FormFieldPhone
            alternate
            icon="phone.svg"
            type="tel"
            id="Field5"
            label="Phone Number*"
            placeholder="(000) 000-0000"
          />
          <FormSelectField
            id="Field7"
            label="Preferred Method Of Communication"
            placeholder="(000) 000-0000"
            :options="['Email', 'Phone Call']"
          />
          <FormField
            alternate
            type="text"
            id="Field9"
            label="Ideal Number Of Seats"
            placeholder="#"
          />
          <div class="checkbox">
            <input checked type="checkbox" name="Field11" id="Field11" />
            <label for="Field11"
              >By submitting your email address, you are agreeing to the KC
              Current
              <router-link :to="{ name: 'Privacy Policy' }"
                >Privacy Policy</router-link
              >.</label
            >
          </div>
          <div style="display: none">
            <label for="comment">Do Not Fill This Out</label>
            <textarea name="comment" id="comment" rows="1" cols="1"></textarea>
            <input
              type="hidden"
              id="idstamp"
              name="idstamp"
              value="4XNqf727PfHN0bKIcIeHGrpVEroEy76Yd31L5oFrKSQ="
            />
            <input
              type="hidden"
              id="encryptedPassword"
              name="encryptedPassword"
              value=""
            />
          </div>
        </div>
        <RoundedButton text="Submit" icon="circle-arrow.svg" alternate />
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RoundedButton from "@/components/RoundedButton";
import FormField from "@/components/FormField";
import FormFieldPhone from "@/components/FormFieldPhone";
import FormSelectField from "@/components/FormSelectField";
export default {
  name: "TicketInterest",
  components: {
    RoundedButton,
    FormField,
    FormSelectField,
    FormFieldPhone,
  },
  data() {
    return {
      firstName: "",
      success: false,
      formErrors: [],
      testEmail: /^.+@.+\..+$/,
    };
  },
  methods: {
    submit(e) {
      const formData = new FormData(e.target);
      const validateData = [];
      formData.forEach((value, key) => (validateData[key] = value));
      this.formErrors = [];
      if (validateData.Field3 === "") {
        this.formErrors.push("First Name");
      }
      if (validateData.Field4 === "") {
        this.formErrors.push("Last Name");
      }
      if (
        validateData.Field5 === "" ||
        validateData["Field5-1"] === "" ||
        validateData["Field5-2"] === ""
      ) {
        this.formErrors.push("Phone Number");
      }
      if (
        validateData.Field1 === "" ||
        !this.testEmail.test(validateData.Field1)
      ) {
        this.formErrors.push("Email Address");
      }
      if (validateData.Field11 !== "on") {
        this.formErrors.push("Accepted Terms");
      }
      if (this.formErrors.length > 0) {
        alert("Please enter all required fields.");
        return;
      }
      axios
        .post(e.target.action, formData)
        .then((res) => {
          this.$gtag.event("conversion", {
            allow_custom_scripts: true,
            send_to: "DC-12329991/conve0/kccur0+standard",
          });
          this.$gtag.event('conversion', {
            send_to: 'AW-10973243387/fdFmCKnPz9oDEPvPufAo',
          });
          fbq('track', 'Lead'); 
          this.success = true;
        })
        .catch((error) => {
          // error.response.status Check status code
          alert("An error has occurred. Please try again later.");
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: var(--teal);
}
.checkbox {
  display: flex;
  gap: 15px;
  color: var(--white);
  font-family: "Montserrat";
  font-weight: 400;
  text-align: left;
  align-items: flex-start;
  label {
    user-select: none;
  }
  input {
    width: 20px;
    height: 20px;
    text-align: center;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.125rem;
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background: #ffffff;
      border-radius: 2px;
      display: block;
    }
    &:checked:before {
      content: "✓";
    }
  }
}
#ticket-interest {
  max-width: 830px;
  margin: 0 auto;
  padding: 100px 25px;
  position: relative;
  z-index: 1;
}
h2 {
  margin-top: 0;
  font-size: 2.5rem;
}
p {
  color: var(--teal);
}
form {
  .fields {
    display: grid;
    padding: 25px;
    @media (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }
    gap: 25px;
    margin-bottom: 25px;
    .form-field {
      margin-bottom: 0;
    }
  }
}
</style>