<template>
  <div id="promotions">
    <img src="@/assets/promos/pattern.png" alt="" class="pattern">
    <div class="promotions">
        <div v-for="promo in promos" class="promotion">
            <img :src="getPromoSrc(promo.image)" :alt="promo.title" />
            <div class="info">
                <p class="title">{{promo.title}}</p>
                <p class="date">{{promo.date}}</p>
            </div>
            <div class="ctas">
                <a v-for="link in promo.ctas" class="promo-cta" :href="link.url" target="_blank" rel="noopener noreferrer">{{link.cta}}</a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    name:'Promos',
    data() {
        return {
            promos:[]
        }
    },
    created() {
        axios.post('https://cpkcstadium.com/data-api/promos').then(this.onGetPromos).catch(this.onError)
    },
    methods : {
        getPromoSrc(image) {
            if(image.includes('http')) {
                return image;
            }
            return require(`@/assets/promos/${image}`)
        },
        onGetPromos(response) {
            console.log(response)
            this.promos = response.data
        },
        onError(e) {
            console.log(e)
        }
    },    
}
</script>

<style lang="scss" scoped>
    #promotions {
     position: relative;   
     padding:6.25rem 0;
    }
    .pattern {
        position: absolute;
        top:0;
        left:0;
        max-width: 100vw;
    }
    .promotions {
        display: flex;
        flex-direction: column;
        gap:5rem;
        justify-content: center;
        align-items: stretch;
        text-align: center;
        margin:0 2.5rem;  
        position: relative;      
        @media (min-width:730px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap:2.5rem;
            & > :only-child {
               grid-column: span 2;
               margin:0 auto;
            }
        }
        @media (min-width:1348px) {
            grid-template-columns: 634px 634px;            
        }
    }
    .promotion {        
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;          
        max-width:634px;
        .info {
            flex-grow: 1;
            margin-top:2.13rem;
        }
        img {
            width:100%;            
        }        
    }
    .ctas {
        display: flex;
        gap:0rem;
        flex-direction: column;
        @media (min-width:485px) {
            flex-direction: row;
            gap:1rem;
        }
        a {
            @media (hover: hover) {
                transition: all 0.2s ease-in-out;                
                &:hover {                    
                    color: var(--blue);
                    background: var(--teal);                                
                }
            }
        }
    }
    p {
        color: var(--white);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
        font-family: 'Custom Current Type';
        font-size: 2rem;
        line-height: 1.03;
        text-transform: uppercase;
        margin:0;        
        &.date {
            margin-top:0.5rem;
        }
    }
    .promo-cta {
        display: inline-flex;
        padding: 0.75rem 2.8125rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 31.25rem;
        background: var(--red);
        color: var(--white);
        font-family: "Montserrat";
        font-size: 0.875rem;
        font-weight: 700;
        letter-spacing: 0.00875rem;
        text-transform: uppercase;
        margin-top:1.3rem;
    }
</style>