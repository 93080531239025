import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Loading from '../views/Loading.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: Home,
    props : {
      buynow:true
    }
  },  
  {
    path: '/live-cam',
    name: 'Live Cam',
    redirect: to => {
      return { name:'Home' }
    },
    //component: () => import(/* webpackChunkName: "seating" */ '../views/LiveCamera.vue'),
    //props: {
//      src:'https://share.earthcam.net/embed/tJ90CoLmq7TzrY396Yd88I4WbklAjsbD9J9SC-40AxI!/tJ90CoLmq7TzrY396Yd88JYXDg4AbD0pEh4u831SD1Y!'
  //  }
  }, 
  {
    path: '/news',
    name:'News',
    //component: () => import(/* webpackChunkName: "news" */ '../views/News.vue')
    redirect: to => {
      return { name:'Home' }
    },
  },
  {
    path: '/news/:article',
    //component: () => import(/* webpackChunkName: "news" */ '../views/CMSArticle.vue')
    redirect: to => {
      return { name:'Home' }
    },
  },
  {
    path: '/galleries',
    name:'Galleries',
    //component: () => import(/* webpackChunkName: "news" */ '../views/Galleries.vue')
    redirect: to => {
      return { name:'Home' }
    },
  },
  {
    path: '/galleries/embed/:gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/CMSGallery.vue'),
    props: {
      embedded:true
    }
  },
  {
    path: '/galleries/:gallery',
    //component: () => import(/* webpackChunkName: "gallery" */ '../views/CMSGallery.vue'),
    //props: {
     // embedded:false
    //}
    redirect: to => {
      return { name:'Home' }
    },
  },
  {
    path: '/letter',
    name: 'Letter to Kansas City',
    component: () => import(/* webpackChunkName: "letter" */ '../views/Letter.vue')
  },
  {
    path: '/our-values',
    name: 'Our Values',
    component: () => import(/* webpackChunkName: "our-values" */ '../views/OurValues.vue')
  },
  {
    path: '/stadium',
    name: 'Stadium',
    component: () => import(/* webpackChunkName: "stadium" */ '../views/Stadium.vue')
  },
   {
    path: '/tickets',
    name: 'Tickets',    
    component: () => import(/* webpackChunkName: "multi-seating" */ '../views/MultiSeating.vue'),
    props : {
      links : [
        {
          id:'premium-seating',
          title:'Premium Seating',
          info:'Limited premium seating options are now available to purchase. Click into each premium area to learn more about the seating experience.',
          links : [
            {
              link:'Pitch Club',
              label:'Pitch Club',
              file:'premium-seating/pitch-club-bar.jpg'
            },
            {
              link:'East Loge Box',
              label:'East Loge Box',
              file:'seating/premium/loge-box.jpg'
            },
            {
              link:'Loge Rail',
              label:'Loge Rail',
              file:'seating/premium/loge-rail.jpg'
            },
            {
              link:'North Rails',
              label:'North Rails',
              file:'premium-seating/half-moon-limited.jpg'
            },
          ],
        },
        {
          id:'seating',
          title:'Seating',
          links: [
            {
              link:'Supporters',
              label:'Supporters',
              file:'seating/supporters.jpg'
            },
            {
              link:'General Reserve',
              label:'General Reserve',
              file:'seating/general.jpg'
            },
            {
              link:'West Sideline Club',
              label:'West Sideline Club',
              file:'seating/club-seating.jpg'
            },
            {
              link:'Suites',
              label:'Suites',
              file:'seating/suites.jpg'
            },
          ]
        },
      ]
    }
  },
  {
    path: '/seating',
    name: 'Seating',    
    component: () => import(/* webpackChunkName: "seating" */ '../views/Seating.vue'),
    props : {
      links : [
        {
          link:'Supporters',
          label:'Supporters',
          file:'seating/supporters.jpg'
        },
        {
          link:'General Reserve',
          label:'General Reserve',
          file:'seating/general.jpg'
        },
        {
          link:'West Sideline Club',
          label:'West Sideline Club',
          file:'seating/club-seating.jpg'
        },
        {
          link:'Suites',
          label:'Suites',
          file:'seating/suites.jpg'
        },
      ]
    }
  },
  {
    path: '/seating/club-seating',
    name: 'West Sideline Club',
    component: () => import(/* webpackChunkName: "seating" */ '../views/ClubSeating.vue')
  },
  {
    path: '/seating/general-reserve',
    name: 'General Reserve',
    component: () => import(/* webpackChunkName: "seating" */ '../views/GeneralReserve.vue')
  },
  {
    path: '/seating/supporters',
    name: 'Supporters',
    component: () => import(/* webpackChunkName: "seating" */ '../views/Supporters.vue')
  },
  {
    path: '/seating/suites',
    name: 'Suites',
    component: () => import(/* webpackChunkName: "seating" */ '../views/Suites.vue'),
  },    
  {
    path: '/premium',
    name: 'Premium Seating',    
    component: () => import(/* webpackChunkName: "seating" */ '../views/Seating.vue'),
    props : {
      links : [
        {
          link:'Pitch Club',
          label:'Pitch Club',
          file:'premium-seating/pitch-club-bar.jpg'
        },
        {
          link:'East Loge Box',
          label:'East Loge Box',
          file:'seating/premium/loge-box.jpg'
        },
        {
          link:'Loge Rail',
          label:'Loge Rail',
          file:'seating/premium/loge-rail.jpg'
        },
        {
          link:'North Rails',
          label:'North Rails',
          file:'premium-seating/half-moon-limited.jpg'
        },
      ]
    }
  },
  {
    path: '/premium/pitch-club',
    name: 'Pitch Club',
    component: () => import(/* webpackChunkName: "premium" */ '../views/PremiumSeatingTemplate.vue'),
    props : {
      heading:'Premium Seating',
      background:'pitch-club-bar.jpg',
      images: ["photos/pitch-club/img-pitch-club-01.jpg", "photos/pitch-club/img-pitch-club-02.jpg", "photos/pitch-club/img-pitch-club-03.jpg", "photos/pitch-club/img-pitch-club-04.jpg", "photos/pitch-club/img-pitch-club-05.jpg", "photos/pitch-club/img-pitch-club-06.jpg", "photos/pitch-club/img-pitch-club-07.jpg"],
      intro:"The Pitch Club at CPKC Stadium reimagines exclusivity and proximity in sports entertainment. A Pitch Club membership is your pass to a center stage seat, putting you just feet from the pitch and the best women’s soccer players in the world. The membership is also your backstage pass to what will be one of the most exclusive entertainment experiences in downtown Kansas City. In a climate-controlled, art-deco inspired lounge, stadium chefs Megan and Colby Garrelts (Rye KC) curate a local, seasonally inspired rotating menu paired with a custom-made craft cocktail list. Every detail for Pitch Club members is sorted, with VIP Parking, dedicated Premium Entry and concierge. A Pitch Club membership is your opportunity to capture matchday experiences in a way no one ever has.",
      outro:"",
      benefits : [
        {
          file: {
            path:'premium-seating/limited',
            ext:'jpg'
          },
          title:'Limited Availability',
          info:'Starting in 2024 at CPKC Stadium, the Pitch Club will be the premier destination in sports and entertainment. As stadium construction continues throughout 2023, we are making this world-class premium space available only to 2023 Field Club Season Ticket Members starting in April.'
        },
        {
          file: {
            path:'premium-seating/seamless',
            ext:'jpg'
          },
          title:'Seamless Arrival',
          info:'VIP Parking and private Premium Entry for seamless matchday arrival and departure.'
        },
        {
          file: {
            path:'premium-seating/upscale',
            ext:'jpg'
          },
          title:'Upscale, Local Food & Drink',
          info:'A seasonal, rotating menu curated by stadium chefs Megan and Colby Garrelts, of Rye KC, will feature modern takes on classic dishes that Kansas Citians know and love. A full, premium bar with customized cocktails will delight you and your guests throughout match day. '
        }, 
        {
          file: {
            path:'premium-seating/pitch-proximity',
            ext:'jpg'
          },
          title:'Proximity to Pitch',
          info:'Located just 15 feet from the sideline, the Pitch Club features cool, comfortable mesh seating to enjoy a match in all weather conditions.'
        }, 
      ]
    }
  }, 
  {
    path: '/premium/loge-box',
    name: 'East Loge Box',
    component: () => import(/* webpackChunkName: "premium" */ '../views/PremiumSeatingTemplate.vue'),
    props : {
      heading:'Premium Seating',
      background:'hero-loge-box.jpg',      
      images: ["photos/loge-box/img-loge-box-01.jpg", "photos/loge-box/img-loge-box-02.jpg", "photos/loge-box/img-loge-box-03.jpg", "photos/loge-box/img-loge-box-04.jpg"],
      intro:"CPKC Stadium’s Loge Boxes are “mini-suites” that offer a semi-private experience on both the West and East Concourse of the stadium. Each Loge Box includes various levels of premium food and beverage experiences along with unrivaled sightlines, quick concourse access and VIP parking. Our limited Loge Box offerings are the perfect way to elevate your match day.",
      outro:"Contact us to learn more about getting in line for this premium seating experience.",
      benefits : [
        {
          file: {
            path:'premium-seating/loge-limited',
            ext:'jpg'
          },
          title:'Limited Availability',
          info:'The West Loge Box is SOLD OUT, and we are now Waitlist-only for East Loge locations.'
        },
        {
          file: {
            path:'premium-seating/seamless',
            ext:'jpg'
          },
          title:'Seamless Arrival',
          info:'VIP Parking and an expedited, private Premium Entry for seamless matchday arrival and departure'
        },
        {
          file: {
            path:'premium-seating/loge-private',
            ext:'jpg'
          },
          title:'Private Seating',
          info:'Semi-enclosed seating exclusive to your matchday party.'
        }, 
        {
          file: {
            path:'premium-seating/unmatched',
            ext:'jpg'
          },
          title:'Unrivaled Sightlines',
          info:'Located on concourse level, Loge Boxes provide each attendee with a uninterrupted vantage point to watch play unfold.'
        }, 
      ]
    }
  }, 
  {
    path: '/premium/loge-rail',
    name: 'Loge Rail',
    component: () => import(/* webpackChunkName: "seating" */ '../views/PremiumSeatingTemplate.vue'),
    props : {
      heading:'Premium Seating',
      background:'hero-loge-rail.jpg',
      images: ["photos/loge-rail/img-loge-rail-01.jpg", "photos/loge-rail/img-loge-rail-02.jpg", "photos/loge-rail/img-loge-rail-03.jpg", "photos/loge-rail/img-loge-rail-04.jpg"],
      intro:"Loge Rail seating is located on the West side of the pitch in the north and south corners of the stadium giving you fantastic views of primetime goals and game-changing saves. Featuring five rows of eight seats, Loge Rail pass holders receive all-inclusive snacks and grab-and-go drinks. With dedicated in-seat service and QR code ordering capabilities, you never need to miss a moment of action. ",
      outro:"Loge Rail seating requires the purchase of either four (4) or eight (8) seats. This seating experience is on-sale now. Contact us today to learn more! ",
      benefits : [
        {
          file: {
            path:'premium-seating/loge-rail-limited',
            ext:'jpg'
          },
          title:'Limited Availability',
          info:'The Loge Rail is limited to 80 seats (40 in the North and 40 in the South), making this one of the most exclusive areas inside the stadium.'
        },
        {
          file: {
            path:'premium-seating/seamless',
            ext:'jpg'
          },
          title:'Seamless Arrival',
          info:'VIP Parking and expedited, private Premium Entry for seamless matchday arrival and departure'
        },
        {
          file: {
            path:'premium-seating/loge-premium',
            ext:'jpg'
          },
          title:'Premium Social Space',
          info:'The Concourse Club is a premium social space exclusively available to Loge Box, Loge Rail, and Concourse Club ticket holders.'
        }, 
        {
          file: {
            path:'premium-seating/concourse',
            ext:'jpg'
          },
          title:'Concourse Club Access',
          info:'Located directly behind your West Loge Box is the Concourse Club featuring grab-and-go beverages and snacks along with additional lounge seating. With views of the stadium bowl from the bar, you can grab a drink with friends while keeping an eye on the action.'
        }, 
      ]
    }
  },
  {
    path: '/premium/half-moon-tables',
    redirect: to => {
      return { name:'North Rails' }
    },
  } ,
  {
    path: '/premium/north-rails',
    name: 'North Rails',
    component: () => import(/* webpackChunkName: "seating" */ '../views/PremiumSeatingTemplate.vue'),
    props : {
      heading:'Premium Seating',
      background:'hero-half-moon.jpg',
      images: ["photos/north-rails/img-half-moon-01.jpg", "photos/north-rails/img-half-moon-02.jpg", "photos/north-rails/img-half-moon-03.jpg"],
      intro:"North Rails will be one of the most unique matchday experiences in all of American soccer. Located on the North side of the stadium and only one row from the pitch, these locations put a VIP Table in the middle of the action. These sets of four seats surround a private, half-circle table for convenience and comfortability. This seating experience includes a pre-stocked cooler and staged snacks waiting for your arrival.",
      outro:"North Rails are on sale now and include four (4) season tickets. Contact us to learn more today!",
      sold:true,
      benefits : [
        {
          file: {
            path:'premium-seating/half-moon-limited',
            ext:'jpg'
          },
          title:'Limited Availability',
          info:'With only eight tables at CPKC Stadium, these premium seating locations will be reserved quickly.'
        },
        {
          file: {
            path:'premium-seating/seamless',
            ext:'jpg'
          },
          title:'Seamless Arrival',
          info:'VIP Parking and expedited, private Premium Entry for seamless matchday arrival and departure'
        },
        {
          file: {
            path:'premium-seating/half-moon-proximity',
            ext:'jpg'
          },
          title:'Proximity to Pitch',
          info:'Located only two rows off the pitch, North Rails put you right on the goal line. The best seat for highlight goals, game-changing saves and team celebrations and interactions. '
        }, 
        {
          file: {
            path:'premium-seating/riverfront',
            ext:'jpg'
          },
          title:'Riverfront Views',
          info:'The YETI Scoreboard Bar is conveniently located directly behind North Rails, with a backdrop of riverfront views making it the ultimate hang out spot.'
        }, 
      ]
    }
  }, 
  {
    path: '/our-story',
    name: 'Our Story',
    component: () => import(/* webpackChunkName: "our-story" */ '../views/OurStory.vue')
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import(/* webpackChunkName: "faqs" */ '../views/Careers.vue')
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: () => import(/* webpackChunkName: "faqs" */ '../views/Faqs.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "legal" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-conditions',
    name: 'Terms Conditions',
    component: () => import(/* webpackChunkName: "legal" */ '../views/TermsConditions.vue')
  },  
  {
    path: '/rules-and-regulations',
    name: 'Rules and Regulations',
    component: () => import(/* webpackChunkName: "legal" */ '../views/StadiumRules.vue')
  },
  { path: "/:pathMatch(.*)*", component: Loading }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    if (to.hash) {      
      setTimeout(() => {        
          const el = document.querySelector(to.hash);
          const yOffset = 50;         
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset - 200;
          window.scrollTo(0, y)
      }, 1);
      return;
    } else {
      return { top: 0 }
    }
  },
})
router.afterEach((to, from, next) => {
  document.title = to.name === 'Home' ? 'CPKC Stadium' : to.meta.name ?? to.name ?? 'CPKC Stadium';
});
export default router
