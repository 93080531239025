import { createStore } from 'vuex'
import axios from "axios"

export default createStore({
  state: {
    CMS: 'https://cpkcstadium.com/',
    CMS_KEYS:'&token=1bc5885317e172e8d851820883077c',
    showDeposit: true,
    emailSubmitted: false,
    premiumSubmitted: false,
    showCountdown:false,
    countdownTo:new Date(Date.UTC(2023, 5, 8, 4, 59, 59)),
    countdownTitle:'CPKC Stadium Deposit Deadline',
    careerFaqs : [
      {
        name: "",
        items: [
          {
            question: "When will the application be available?",
            answer: "Tentatively, early 2024."
          },
          {
            question: "Is this a full-time role?",
            answer: "While several full-time roles will be available at the new stadium, most of our positions will be part-time, event-day roles."
          },
          {
            question: "Where can I find more information about full-time roles?",
            answer: "Full-time roles will be posted on Teamwork Online, <a href='https://www.teamworkonline.com/soccer-jobs/nwslsoccer/kc-woso' target='_blank'>here</a>."
          },
          {
            question: "Will there be any part-time, event day leadership positions available?",
            answer: "YES! These positions will be posted before the main application. More information will follow in the coming weeks as to what positions are available and the timeline for filling them."
          },
          {
            question: "As a part-time event day Team Member, how often would I be expected to work?",
            answer: "Team Members will be expected to work the vast majority of major events, and some smaller events as needed."
          },
          {
            question: "What events will be hosted at CPKC Stadium?",
            answer: "CPKC Stadium will feature KC Current matches and be the home to many more world-class events such as sports, entertainment, private rentals, and community-focused activations."
          },
          {
            question: "Will there be any Team Member perks?",
            answer: "We are working on a variety of exciting discounts and perks that will make this a fun and rewarding Team Member experience!"
          },
          {
            question: "Will there be other opportunities to work?",
            answer: "As the stadium gets busier, we'll pick up more and more small hospitality events... and likely some big ones too!"
          },
        ]
      }
    ],
    faqs: [
      {
        name: "CPKC Stadium",
        items: [
          {
            question: "When will CPKC Stadium open?",
            answer: "The CPKC Stadium at Berkley Riverfront Park has broken ground and is on track to open for the start of the 2024 NWSL season."
          },
          {
            question: "Where will CPKC Stadium be located?",
            answer: "The CPKC Stadium is being built on a 7-acre site on the east end of Berkley Riverfront Park near downtown Kansas City, MO.",
          },
          {
            question: "What will the capacity of CPKC Stadium be?",
            answer: "The CPKC Stadium at Berkley Riverfront Park will feature 11,500 seats.",
          },
        ]
      },      
    ],
    suiteFaqs: {
      name: "Suites",
      items: [
        {
          question: "How much are suite deposits for the new stadium?",
          answer: "A deposit for a suite is $500. The refundable deposit can be applied towards the suite license fee or any other KC Current ticket purchase.",
        },
        {
          question: "Why should I place a suite deposit?",
          answer: "There is no risk – placing your refundable deposit is your only chance to secure your priority to select from the limited number of suites in the new stadium If you later decide not to purchase a suite or are unable to purchase a suite, you can apply your deposit towards other future premium seating products at the new stadium.",
        },
        {
          question:'Are there enough suites for everyone who places a suite deposit?',
          answer:'No- there are a limited number of suites available and those who place a deposit will be placed on the suite priority list and contacted for the opportunity to purchase a suite on a first-come, first-served basis.'
        },
        {
          question:'When can I select a suite?',
          answer:'A KC Current representative will be in touch soon after placing your deposit to discuss your place on the priority list, timeline, pricing, available suite locations and next steps for signing a suite agreement. Suite appointments will begin prior to the general seat selection process of spring 2023 in the new stadium. Suites are subject to availability, and it is expected that they will sell out quickly.'
        },
        {
          question:'Where will suites be located at the new stadium?',
          answer:'Directly above the team benches and just 5 rows from the pitch. The proximity of the suites to the action provides unrivaled sight lines and atmosphere for suite owners and their guests.'
        },
        {
          question:'Do suites include parking for guests?',
          answer:'Yes – an allotment of VIP Parking Passes will be included for each suite to provide a premier arrival experience on matchdays for suite owners and their guests.'
        },
        {
          question:'What payment options are available?',
          answer:'Suite licenses are all sold on multi-year agreements. Suite holders will have the option to pay the suite license fee in full or via annual installments.'
        },
        {
          question:'I am a 2023 Current Season Ticket Member interested in a new stadium suite; do I need to place a suite deposit?',
          answer:'Yes – your 2023 Current Season Ticket Membership establishes your seat selection priority for your 2024 season ticket member seat locations (in the Premium, General, or Supporters Section) in the new stadium. A separate deposit is required if you are interested in purchasing a suite.'
        },
        {
          question:'What other benefits or amenities come with being a suite holder?',
          answer:'KC Current is designing VIP arrival and bespoke in-match food & beverage experiences that will represent the region and uniquely embody Kansas City. Suite ownership extends well beyond matchdays with additional exclusive benefits accessible year-round.'
        },

        {
          question: "Who can I contact for more information on suites?",
          answer: "For more information on suites for the new CPKC Stadium at Berkley Riverfront Park, please contact Brad Thomas at 618-580-6568 or via email at <a href='mailto:brad.thomas@kansascitycurrent.com.' target='_blank'>brad.thomas@kansascitycurrent.com</a>."
        }
      ]
    },
    modalImages: [],
    modalInitialImage: 0,
    homepageStadiumRenderings: [      
      "photos/home/img-home-carousel-Southwest-Plaza.jpg",
      "photos/home/img-home-carousel-West-Upper-Club-Seats.jpg",
      "photos/home/img-home-carousel-SW-Corner.jpg",
      "photos/home/img-home-carousel-South-Concourse-Club.jpg",
      "photos/home/img-home-carousel-East-Upper-Deck.jpg",
      "photos/renderings/img-rendering-6.jpg",
      "photos/home/img-home-carousel-Suite-interior-view.jpg",
      "photos/home/img-home-carousel-Scoreboard-bar.jpg",
      
    ],
    stadiumRenderings: [
      "photos/home/img-home-carousel-SW-Corner.jpg",
      "photos/home/img-home-carousel-South-Concourse-Club.jpg",      
      "photos/home/img-home-carousel-East-Upper-Deck.jpg",
      "photos/renderings/img-rendering-6.jpg",
      "photos/home/img-home-carousel-Suite-interior-view.jpg",
      "photos/home/img-home-carousel-Scoreboard-bar.jpg", 
    ],
    photoInfo: {      
      "photos/home/img-home-carousel-Southwest-Plaza.jpg" : {
        title:"Southwest Plaza",
        description:"The main entry gate for CPKC Stadium."
      },
      "photos/home/img-home-carousel-West-Upper-Club-Seats.jpg" : {
        title:"West Upper Club Seat View",
        description:"Expect an unparalleled, electric atmosphere at every single home match.",
      },
      "photos/home/img-home-carousel-SW-Corner.jpg": {
        title:"SW Corner from Berkley Parkway",
        description:"A magnificent curved, undulating canopy for KC the stadium’s south end.",
      },
      "photos/home/img-home-carousel-South-Concourse-Club.jpg": {
        title:"West Sideline Club",
        description:"A premium space for West Sideline Club members.",
      },
      "photos/home/img-home-carousel-East-Upper-Deck.jpg": {
        title: "East Upper Deck",
        description: "Downtown views on the left, Riverfront views on the right. The best women’s soccer club in the world in the middle."
      },
      "photos/home/img-home-carousel-Suite-interior-view.jpg": {
        title: "Suite - Interior View",
        description: "A new luxury hospitality experience in Downtown KC."
      },
      "photos/home/img-home-carousel-Scoreboard-bar.jpg": {
        title: "YETI Scoreboard Bar",
        description: "Riverfront views, just rows from the action on the pitch."
      },
      "photos/renderings/img-rendering-6.jpg": {
        title: "Suite - Field View",
        description: "A suite experience unrivaled in professional sports, just rows from the pitch."
      },
    },
    galleryPages:[],
    newsPages:[],
    careers:[],
  },
  getters: {
  },
  mutations: {
    setModalImages: (state, payload) => {
      state.modalImages = payload;
    },
    setModalInitialImage: (state, payload) => {
      state.modalInitialImage = payload;
    },
    setEmailSubmitted: (state) => {
      state.emailSubmitted = true;
    },
    setPremiumSubmitted: (state) => {
      state.premiumSubmitted = true;
    },    
    setGalleries: (state, payload) => {
      state.galleryPages = payload
    },
    setCareers: (state, payload) => {
      state.careers = payload
    },
    setNews: (state, payload) => {
      state.newsPages = payload
    },
  },
  actions: {
    fetchCareers({ commit, state }) {
      try {        
        axios.get(
          `${state.CMS}/admin/api/collections/get/Careers?${state.CMS_KEYS}`
        ).then((response )=> {
          commit('setCareers', response.data.entries)
        })        
      }
      catch (error) {
          console.log(error)
      }
    },
    fetchGalleries({ commit, state }) {
      try {        
        axios.get(
          `${state.CMS}/admin/api/collections/get/Galleries?${state.CMS_KEYS}`
        ).then((response )=> {
          commit('setGalleries', response.data.entries)
        })        
      }
      catch (error) {
          console.log(error)
      }
    },
    fetchNews({ commit, state }) {
      try {        
        axios.get(
          `${state.CMS}/admin/api/collections/get/Articles?${state.CMS_KEYS}`
        ).then((response )=> {
          commit('setNews', response.data.entries)
        })        
      }
      catch (error) {
          console.log(error)
      }
    }
  },
  modules: {
  }
})
