<template>
  <div
    id="seating"
    :style="`background-image:url('${require('@/assets/backgrounds/seating.png')}')`"
  >
    <h2>Seating</h2>
    <h3>From a reimagined premium experience to a rocking supporter stand, there’s a seat in history for everyone.</h3>    
    <div class="flex">
      <ImageButton hideMagnify @click="$emit('pause');" link="Supporters" label="Supporters" file="seating/supporters.jpg"/>
      <ImageButton hideMagnify @click="$emit('pause');" link="General Reserve" label="General Reserve" file="seating/general.jpg"/>
      <ImageButton hideMagnify @click="$emit('pause');" link="West Sideline Club" label="West Sideline Club" file="seating/club-seating.jpg"/>
      <ImageButton hideMagnify @click="$emit('pause');" link="Suites" label="Suites" file="seating/suites.jpg"/>
    </div>
  </div>
</template>

<script>
import ImageButton from "@/components/ImageButton";
export default {
  name: "SeatingSection",
  components: {
    ImageButton,
  },
  data() {
    return {
      images: [
        "photos/seating/supporters.jpg",
        "photos/seating/general.jpg",
        "photos/seating/club-seating.jpg",
        "photos/seating/suites.jpg",
      ]

    }
  }
};
</script>

<style lang="scss" scoped>
h3 {
  max-width: 600px;
}
#seating {
  background-size: cover;
  padding: 135px 0;
  padding-bottom:50px;
  background-position: center;
  > * {
    padding:0 50px;
  }
  .flex {    
    gap: 30px;
    padding: 50px;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media (min-width:600px) {
      flex-direction: row;
      > * {
        width:25%;
      }
    }
    > * {
      width:100%;
      aspect-ratio: 0.9;      
      flex-grow: 1;
    }
  }
}
</style>