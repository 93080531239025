<template>
  <button v-if="link === ''">
    <div>
      <img :src="require(`${'@/assets/photos/'}${file}`)" alt="" />
      <div class="icon" v-if="!hideMagnify">
        <p class="label" v-if="label !== ''">{{ label }}</p>
        <img v-svg-inline src="@/assets/icons/magnify.svg" alt="" />
      </div>
    </div>
  </button>
  <router-link v-else :to="{name:link}">
    <div>
      <img :src="require(`${'@/assets/photos/'}${file}`)" alt="" />
      <div class="icon">
        <p class="label" v-if="label !== ''">{{ label }}</p>
        <img v-if="!hideMagnify" v-svg-inline src="@/assets/icons/magnify.svg" alt="" />
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ImageButton",
  props: {
    hideMagnify: Boolean,
    link: {
      type: String,
      default: "",
    },
    file: {
      type: String,
      default: "placeholder.jpg",
    },
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
button, a {
  background: none;
  border: none;
  font-size: 0;
  padding: 0;
  position: relative;
  width: inherit;
  height: inherit;
  text-decoration: none;
  > div {
    position: relative;
    height: 100%;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (hover: hover) {
    > div {
      transition: all 0.2s ease-in-out;
      > img {
        transition: all 0.2s ease-in-out;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--teal);
      transition: all 0.2s ease-in-out;
      opacity: 0;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    }
    .icon,
    .label,
    svg {
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      > div {
        > img,
        .icon {
          transform: translateY(-8px);
        }
      }
      &:before {
        transform: translateX(8px);
        background: var(--teal);
        opacity: 1;
      }
      svg {
        fill: var(--blue);
      }
      .icon {
        background: var(--teal);
      }
      .label {
        color: var(--blue);
      }
    }
  }
}
.icon {
  padding: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--blue);
  display: flex;
  gap: 15px;
  align-items: center;
  pointer-events: none;
}
.label {
  font-family: "Alternate Gothic No3 D";
  font-size: 1rem;
  color: var(--white);
  text-transform: uppercase;
  margin: 0;
}
</style>