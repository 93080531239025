<template>
  <button v-if="link === ''" :class="{ 'has-icon': icon !== '', alternate: alternate, small:small }">
    {{ text }}
    <img
      v-svg-inline
      v-if="icon !== '' && !small"
      :src="require(`@/assets/icons/${icon}`)"
      alt=""
    />
  </button>
  <a v-else-if="link.includes('http')" class='external' target="_blank" :href="link">
    {{ text }}
    <img
      v-svg-inline
      v-if="icon !== '' && !small"
      :src="require(`@/assets/icons/${icon}`)"
      alt=""
    />
  </a>
  <router-link v-else :to="{name:link, hash:`${hash}`}">
    {{ text }}
    <img
      v-svg-inline
      v-if="icon !== '' && !small"
      :src="require(`@/assets/icons/${icon}`)"
      alt=""
    />
  </router-link>
</template>

<script>
export default {
  name: "RoundedButton",
  props: {
    text: String,
    alternate: Boolean,
    small:Boolean,
    link:{
      type:String,
      default:'',
    },
    hash: {
      default: "",
      type: String,
    },
    icon: {
      default: "",
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  cursor: pointer;
}
a, button, .external {
  text-decoration: none;
  border: none;
  background: var(--red);
  color: var(--white);
  display: inline-block;
  border-radius: 20px;
  text-transform: uppercase;
  padding: 12px 45px;
  user-select: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  will-change: transform, box-shadow;
  user-select: none;
  &:not(.small){
    animation: float 6s ease-in-out infinite;
  }
  &.small {
    padding: 8px;
    width:90px;
  }
  &.alternate {
    background: var(--teal);
    color: var(--blue);
  }
  @media (hover: hover) {
    transition: all 0.2s ease-in-out;
    svg:deep() {
      circle, path {
        transition: all 0.2s ease-in-out;
      }
    }
    &:hover {
      color: var(--blue);
      background: var(--teal);
      &.small {
        color: var(--teal);
        background: var(--blue);
      }
      svg:deep() {
        circle {
          fill: var(--blue);
        }
        path {
          fill: var(--white);
        }
      }
      &.alternate svg:deep() {
        circle {
          fill: var(--teal);
        }
        path {
          fill: var(--blue);
        }
      }
    }
    &.alternate:hover {
      color: var(--white);
      background: var(--red);
    }
  }
}
.has-icon {
  max-width: 255px;
  width: 100vw;
  img,
  svg {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  &.alternate {
    svg:deep() {
      circle{
      fill:var(--red);
      }
      path {
        fill:var(--white);
      }
    }
  }
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-8px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}
</style>