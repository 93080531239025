<template>
  <button class='menu-button'>
    <img src="@/assets/icons/menu.svg" alt="">
    <img class='close' src="@/assets/icons/close.svg" alt="">
  </button>
</template>

<script>
export default {
    name:'MenuButton'
}
</script>

<style lang="scss" scoped>
button {
    background:var(--teal);
    border:none;
    width:70px;
    height:70px;
    display:grid;
    place-items: center;
    grid-template-areas: 'a';
    padding:0;
     transition: background 1s;
    img {
      grid-area: a;
      transition: all 1s;
    }
    .close {
      transform:rotateY(90deg) scale(0.5);
    }
    &.active {
      background:transparent;
      img {
        transform:rotateY(90deg) rotateZ(45deg) scale(0.5);      
      }
      .close {
        transform:rotateY(180deg) scale(1); 
      }
    }
    
}
</style>