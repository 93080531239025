<template>
  <header ref="header" :class="{ 'show-mobile': showMobileMenu }">
    <router-link
      @click="
        showMobileMenu = false;
        $emit('pause');
      "
      class="logo"
      :to="{ name: 'Home' }"
    >
      <img src="@/assets/logos/cpkc-stadium.svg" alt="" width="180"/>
    </router-link>
    <MenuButton
      :class="{ 'has-mobile': showMobile, active: showMobileMenu }"
      @click.stop="showMobileMenu = !showMobileMenu"
    />
    <Countdown/>
    <div
      @mousewheel.stop
      class="nav-wrapper mobile-wrapper"
      :class="{ 'has-mobile': showMobile }"
    >
      <nav>
        <div
          v-for="item in navItems"
          :key="item.name"
          @mouseover="showDropdown(item)"
          @mouseout="hideDropdown(item)"
        >
          <component :is="item.href ? 'a' : 'router-link'"
          :target="item.href ? '_blank' : null"
          :href="item.href ? item.href : null"
            class='accordian-button'            
            @click="onClickMain(item, $event)"
            :to="item.href ? null : { name: item.name }"
            >{{ item.label ?? item.name }}</component
          >          
          <transition name="fade">
            <div class="dropdown" v-if="item.children" v-show="item.hover">
              <router-link
                :key="section.name"
                @click="
                  showMobileMenu = false;
                  $emit('pause');
                  hideDropdown(item);
                "
                v-for="section in item.children"
                :to="{
                  name: section.hash ? item.name : section.name,
                  path:section.path ?? '',
                  hash: `${section.hash ? `#${section.hash}` : ''}`,
                }"
                :class="{
                  'is-active':
                    !['Premium Seating', 'Seating' , 'Tickets'].includes($route.name) && ($route.name === section.name || $route.name === item.name) &&
                    (section.hash  === undefined || $route.hash === `#${section.hash}`),             
                }"
                >{{ section.title ?? section.name ?? '' }}</router-link
              >
            </div>
          </transition>
        </div>
      </nav>
      <BuyTickets v-if="$store.state.showDeposit" />
    </div>
  </header>
</template>

<script>
import Countdown from "@/components/Countdown";
import BuyTickets from "@/components/BuyTickets";
import MenuButton from "@/components/MenuButton";
export default {
  name: "Nav",
  components: {
    Countdown,
    BuyTickets,
    MenuButton,
  },
  watch : {
    galleryItems(value, newValue) {
      let items = [...this.defaultNavItems]
      /*
       if(this.newsItems) {
        items.push(this.newsItems);
      }
      if(this.galleryItems) {
        items.push(this.galleryItems);
      }
      */
      items.push({ dynamicRoute:false, timeout: -1, hover: false, name: "FAQs" });       
      this.navItems = items
    },
    newsItems(value, newValue) {
      let items = [...this.defaultNavItems]
      /*
       if(this.newsItems) {
        items.push(this.newsItems);
      }
      if(this.galleryItems) {
        items.push(this.galleryItems);
      }
      */
      items.push({ dynamicRoute:false, timeout: -1, hover: false, name: "FAQs" });
      this.navItems = items
    },
  },
  data() {
    return {
      showMobile: false,
      showMobileMenu: false,
      widthMobileTurnedOn: undefined,
      navItems : [],
      defaultNavItems: [
        /*
        {
          dynamicRoute:false,
          timeout: -1,
          hover: false,
          name: "Live Cam",                    
        },
        */
       { dynamicRoute:false, timeout: -1, hover: false, name: "SCHEDULE", href:"http://www.kansascitycurrent.com/schedule" },       
        {
          dynamicRoute:false,
          timeout: -1,
          hover: false,
          name: "Tickets",
          label:"Tickets",
           children: [
            { name: "Pitch Club" }, 
            { name: "East Loge Box" },
            { name: "Loge Rail" }, 
            { name: "North Rails" }, 
            { name: "General Reserve" }, 
            { name: "Supporters" },
            { name: "West Sideline Club" }, 
            { name: "Suites" }, 
          ],
        },  
        { dynamicRoute:false, timeout: -1, hover: false, name: "Careers"},
        {
          dynamicRoute:false,
          timeout: -1,
          hover: false,
          name: "Stadium",
          children: [
            { hash: "overview", name: "Overview" },
            { hash: "facts-and-figures", name: "Facts & Figures" },
            { hash: "renderings", name: "Gallery" },
            { hash: "berkley-riverfront", name: "Berkley Riverfront" },
            { hash: "transportation", name: "Transportation" },
          ],
        },
        { dynamicRoute:false, timeout: -1, hover: false, name: "Host an Event", href:"https://www.kansascitycurrent.com/host-an-event" },
        {
          dynamicRoute:false,
          timeout: -1,
          hover: false,
          name: "Our Story",
          children: [
            { hash: "brand", name: "Brand" },
            { hash: "commitment-to-club", name: "Commitment to Club" },
            { hash: "commitment-to-community", name: "Commitment to Community" },
            { hash: "founders", name: "Founders" },
            { hash: "commitment-from-cpkc", name: "Commitment from CPKC" },
            { name: "Our Values" }
          ],
        },                
      ],
      active: false,
    };
  },
  computed : {    
    galleryItems() {
      let galleryItems = this.$store.state.galleryPages.map((gallery) => {
        return {
          title:gallery.title,
          path:`/galleries/${gallery.title_slug}`,
        }
      });
      if(galleryItems.length > 0){
        return {
          timeout:-1,
          hover:false,
          name:'Galleries',
          dynamicRoute:true,
        };
      }
      return null;
    },
    newsItems() {
      let newsItems = this.$store.state.newsPages.map((article) => {
        return {
          title:article.title,
          path:`/news/${article.title_slug}`,
        }
      });
      if(newsItems.length > 0){
        return {
          timeout:-1,
          hover:false,
          name:'News',
          dynamicRoute:true,
        };
      }      
      return null;
    },   
  },
  methods: {
    onClickMain(item, e) {
        this.showMobileMenu = false;
        this.$emit("pause");
        this.hideDropdown(item);      
    },
    hideDropdown(item) {
      item.timeout = setTimeout(() => {
        item.hover = false;
      }, 100);
    },
    showDropdown(item) {
      clearTimeout(item.timeout);
      item.hover = true;
    },
    resized() {
      let headerWidth = this.$refs.header.scrollWidth;
      let documentWidth = 7 + document.body.clientWidth;
      if (this.showMobile && documentWidth <= this.widthMobileTurnedOn) {
        return;
      }
      if (headerWidth > documentWidth) {
        this.showMobile = true;
        this.widthMobileTurnedOn = documentWidth;
      } else {
        this.showMobile = false;
        this.showMobileMenu = false;
      }
    },
  },
  mounted() {
    const resizeObserver = new ResizeObserver(this.resized);
    resizeObserver.observe(this.$refs.header);
    this.navItems = this.defaultNavItems;
  },
};
</script>

<style lang="scss" scoped>

.nav-wrapper {
  display:flex;
  justify-content: space-between;
  > button, > a {
    flex-shrink: 0;
    flex-grow: 0;
    padding:1rem;
  }
}
a {
  user-select: none;
}
header {
  background: var(--blue);
  background: linear-gradient(90.18deg, var(--blue) 0%, #0b2b3d 100%);
  display: grid;
  grid-template-columns: 220px 1fr;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 69px;
  filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.3));  
}
.logo {
  display: grid;
  place-content: center;
  height: 69px;
}
.menu-button {
  position: absolute;
  right: 0;
  top: 0;
  &:not(.has-mobile) {
    display: none;
  }
}
.mobile-wrapper {
  &.has-mobile {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    left: 0;
    top: 70px;
    width: 100%;
    height: calc(100vh - 70px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s;
    nav {
      display: block;
      overflow: auto;
      flex-grow: 1;
      a, .accordian-button {
        display: block;
        text-align: left;
      }
      .dropdown {
        //display: block !important;
        display: none !important;
        position: static;
        width: 100%;
      }
      .router-link-active {
        + .dropdown {
          //display: block !important;
        }
      }
    }
    .fade-enter-from,
    .fade-leave-to {
      transition-duration: 0s !important;
      opacity: 1;
    }
  }
}
header.show-mobile {
  height: 100vh;
  .mobile-wrapper {
    opacity: 1;
    pointer-events: all;
  }
}
nav {
  display: flex;
  gap: 5px;
  @media (min-width: 1100px) {
    gap: 50px;
  }
  > div {
    position: relative;
    > a, > .accordian-button {
      display: inline-block;
      padding: 25px 20px;
      white-space: nowrap;
      &.router-link-active {
        color: var(--blue);
        background: var(--teal);
      }
    }    
  }
  :has(.is-active) .accordian-button {
    color: var(--blue);
    background: var(--teal);
  }
}
.accordian-button {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: var(--white);
  text-transform: uppercase;
  background:none;
  border:none;
  font-size: inherit;  
  @media (hover: hover) {
    transition: all 0.2s ease-in-out;
    &:hover {
      background: var(--teal);
      color: var(--blue);
    }
  }
}
.dropdown {
  position: absolute;
  top: calc(100% - 1px);
  width: 230px;
  background: var(--blue);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(98, 203, 201, 0) 0%, #62cbc9 100%) 1;
  a {
    font-weight: 600;
    text-align: left;
    display: block;
    color: var(--white);
    font-size: 0.875rem;
    text-transform: uppercase;
    padding: 12px 0px 12px 25px;
    &:first-child {
      //padding-top: 26px;
    }
    &:last-child {
      //padding-bottom: 30px;
    }
    @media (hover: hover) {
      transition: all 0.2s ease-in-out;
      &:hover {
        background: var(--teal);
        color: var(--blue);
      }
    }
    &.is-active {
      background: var(--teal);
      color: var(--blue);

    }
  }
}
</style>