<template>
  <transition name="fade" appear>
    <div class="countdown-position">
      <div class="countdown-holder" :class="{'no-countdown':!$store.state.showCountdown}">
        <div class="countdown" v-if="$store.state.showCountdown">
          <div class="countdown-title">{{$store.state.countdownTitle}}</div>
          <div class='countdown-elements'>
            <div class="days element">
              <span class="value">{{ days }}</span>
              <span class="label">Days</span>
            </div>
            <img
              class="divider"
              src="@/assets/images/countdown-line.png"
              alt=""
            />
            <div class="hours element">
              <span class="value">{{ hours.toString().padStart(2, "0") }}</span>
              <span class="label">Hours</span>
            </div>
            <img
              class="minutes divider"
              src="@/assets/images/countdown-line.png"
              alt=""
            />
            <div class="minutes element">
              <span class="value">{{
                minutes.toString().padStart(2, "0")
              }}</span>
              <span class="label">Mins</span>
            </div>
            <img
              class="seconds divider"
              src="@/assets/images/countdown-line.png"
              alt=""
            />
            <div class="seconds element">
              <span class="value">{{
                seconds.toString().padStart(2, "0")
              }}</span>
              <span class="label">Secs</span>
            </div>
          </div>
        </div>
        <div class="form-holder" v-if="!$store.state.emailSubmitted">
          <StayConnectedForm inline />
        </div>
        <div class="response-message" v-else style="text-align: center">
          <p class="thanks">Thanks!</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { formatDuration, differenceInSeconds } from "date-fns";
import StayConnectedForm from "@/components/StayConnectedForm";
export default {
  name: "Countdown",
  components: {
    StayConnectedForm,
  },
  mounted() {
    this.countdownTo = this.$store.state.countdownTo;
    this.setCountdown();
  },
  methods: {
    setCountdown() {
      const ONE_DAY = 60 * 60 * 24;
      const ONE_HOUR = 60 * 60;
      const ONE_MINUTE = 60;

      let duration = differenceInSeconds(this.countdownTo, new Date());
      if(duration <= 0) {
        this.$store.state.showCountdown = false;
      }
      this.days = Math.floor(duration / ONE_DAY);
      this.hours = Math.floor((duration - this.days * ONE_DAY) / ONE_HOUR);
      this.minutes = Math.floor(
        (duration - this.days * ONE_DAY - this.hours * ONE_HOUR) / ONE_MINUTE
      );
      this.seconds =
        duration -
        this.days * ONE_DAY -
        this.hours * ONE_HOUR -
        this.minutes * ONE_MINUTE;
      setTimeout(this.setCountdown, 1000);
    },
  },
  data() {
    return {
      showForm: true,
      now: undefined,
      countdownTo: new Date(),
      seconds: "",
      minutes: "",
      hours: "",
      days: "",
    };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 579px) {
  .response-message {
    padding:10px 40px !important;
  }
  .countdown-position .countdown-holder {
    flex-direction: column;
    .element {
      flex-grow: 1;
      align-items: center !important;
    }
    .countdown .divider {
      display: none !important;
      &.minutes,
      &.seconds {
        display: none !important;
      }
    }
    > * {
      transform: skewX(0deg) !important;
      > * {
        transform: skewX(0deg) !important;
      }
    }
    .seconds.divider,
    .minutes.divider,
    .seconds,
    .minutes {
      display: flex !important;
    }
  }
}
@media (max-width: 715px) {
  .seconds.divider,
  .minutes.divider,
  .seconds,
  .minutes {
    display: none !important;
  }
}
@media (max-width: 799px) {
  .seconds-divider,
  .seconds {
    display: none !important;
  }
}
.countdown-position {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  overflow: hidden; 
  .countdown-holder {
    max-width: 750px;
    margin: 0 auto;
    font-size: 4rem;
    user-select: none;
    font-family: "Alternate Gothic No3 D";
    display: flex;
    justify-content: center;
    position: relative;
    left: 20px;
    &.no-countdown {
      &:before {
          content:"";
          background: var(--teal);        
          width:50px;
          height:inherit;
          transform: skewX(30deg);
          margin-left: -40px;
          padding-left: 10px;
          
      }
      .form-holder,
      .response-message {
         @media (min-width:600px) {
          padding-left: 15px;        
        }
      }
    }
    .form-holder,
    .response-message {
      background: var(--teal);
      display: grid;
      align-items: center;
      justify-content: start;
      padding: 10px;
      line-height: 1;
      font-size: 1.125rem;
      text-align: left;
      transform-style: preserve-3d;
      padding-right: 40px;
      padding-left: 40px;
      margin-left: -30px;
      z-index: 1;
      transform: skewX(-30deg);      
      @media (min-width:600px) {
        padding-left: 60px;        
      }
      form {
        transform: skewX(30deg);
        display: flex;
        gap: 10px;
        > div,
        > button {
          flex-shrink: 0;
          flex-grow: 0;
        }
        > div {
          min-width: 195px;
        }
      }
    }
    .response-message {
      display: block;      
      .thanks {
        transform: skewX(30deg);
        margin: 0;
        color: var(--red);
        text-align: center;
      }
    }
    .countdown {
      background: var(--white);
      transform: skewX(30deg);
      transform-style: preserve-3d;
      padding:4px 0;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: -40px;
      z-index: 2;
      position: relative;
      .countdown-title {
        transform: skewX(-30deg);
        font-size: 0.875rem;
        text-align: left;
        padding:2px;
        text-align: center;
        text-transform: uppercase;
        @media (min-width:600px) {
          padding:5px 0 0 27px;
          text-align: left;
        }
      }
     .countdown-elements {      
        transform: skewX(-30deg);
        padding: 10px;
        padding-top:0;
        padding-bottom:0;
        color: var(--blue);
        display: flex;
        gap: 16px;
        text-transform: uppercase;
        text-align: left;
        line-height: 0.8;
        align-items: center;
      }
    }
    .element {
      flex-direction: column;
      display: flex;
      justify-content: space-around;
      font-size: 1.75rem;
      gap: 2px;
      align-items: flex-end;
      @media (min-width: 600px) {
        flex-direction: row;
      }
      .label {
        font-size: 0.75rem;
      }
    }
  }
}
</style>