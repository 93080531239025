<template>
  <FullNav @pause="pauseObservation" v-if="!$route.path.includes('galleries/embed')"/>
  <router-view @pause="pauseObservation" :key="$route.name"/>
  <Footer />
  <Modal v-show="$store.state.modalImages.length > 0" @close="$store.commit('setModalImages', []); $store.commit('setModalInitialImage', 0)">
    <template v-slot:content>
      <FullWidthSlidingGallery :images="$store.state.modalImages" inModal/>
    </template>
  </Modal>
</template>
<script>
import AOS from "aos";
import FullNav from "@/components/FullNav";
import Footer from "@/components/Footer";
import Modal from "@/components/Modal";
import FullWidthSlidingGallery from "@/components/FullWidthSlidingGallery";
export default {
  name: "App",
  components: {
    Modal,
    Footer,
    FullWidthSlidingGallery,
    FullNav
  },
  methods: {
    onScroll(e) {
      if (window.scrollY <= 0) {
        window.location.hash = "";
        return;
      }      
    },
    pauseObservation() {
      this.paused = true;
      setTimeout(() => {
        this.paused = false;
      }, 2000);
    },
    observeSections() {
      try {
        this.sectionObserver.disconnect();
      } catch (error) {}

      const options = {
        rootMargin: "0px 0px",
        threshold: 0.6,
      };
      this.sectionObserver = new IntersectionObserver(
        this.sectionObserverHandler,
        options
      );

      // Observe each section
      let sections = document.querySelectorAll("div[id]:not(#app)");
      sections = [...sections];
      sections.forEach((section) => {
        this.sectionObserver.observe(section);
      });
    },
    sectionObserverHandler(entries) {
      if (this.paused) {
        return;
      }
      if (window.scrollY <= 100) {
        this.$router.replace({ name: this.$route.name, hash: "" });
        return;
      }
      for (const entry of entries) {
        if (
          entry.isIntersecting &&
          this.$route.hash !== `#${entry.target.id}`
        ) {
          const sectionId = entry.target.id;
          // Push sectionId to router here
          this.$router.replace({
            name: this.$route.name,
            hash: `#${sectionId}`,
          });
          return;
        }
      }
    },
  },
  data() {
    return {
      paused: true,
      sectionObserver: undefined,
    };
  },
  watch:{
    '$route.name'() {
      if (window.location.hostname === "localhost") {
        this.$nextTick(() => {
          //this.observeSections();
        })
      }
    }
  },
  mounted() {
    AOS.init();
    if (window.location.hostname === "localhost") {
      //window.addEventListener("scroll", this.onScroll);
      //this.observeSections();
      //this.pauseObservation();
    }
  },
};
</script>

<style lang="scss">
@font-face {
    font-family: 'Alternate Gothic No3 D';
    src: url('~@/assets/fonts/AlternateGotNo3D.woff2') format('woff2'),
        url('~@/assets/fonts/AlternateGotNo3D.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Custom Current Type';
    src: url('~@/assets/fonts/CustomCurrentType-Regular.woff2') format('woff2'),
        url('~@/assets/fonts/CustomCurrentType-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

:root {
  --red: #cf3339;
  --white: #fff;
  --black: #000;
  --blue: #081f2c;
  --light-blue: #0b2b3d;
  --teal: #62cbc9;
}
html,
body,
#app {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  background: #081f2c;  
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--blue);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--teal);
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--blue);
}
a {
  text-decoration: none;
}
</style>

<style lang="scss">
h1 {
  color: var(--teal);
  font-family: "Alternate Gothic No3 D";
  line-height: 1;
  font-size: 2.5rem;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0em;
  text-transform: uppercase;
}
h2 {
  font-family: "Alternate Gothic No3 D";  
  line-height: 0.88;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 2.5rem;
  @media (min-width:1200px) {
    font-size: 3.75rem;
  }
  margin: 20px 0;
  color: var(--white);
  margin-bottom: 24px;
  letter-spacing: 0em;
  &.italic {
    font-size: 4.25rem;
  }
  .teal {
    font-size: 2rem;
    display: block;
    color: var(--teal);
    margin-bottom: 13px;
  }
}
h3 {
  color: var(--teal);
  font-family: "Montserrat";
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.25rem;
  letter-spacing: 0;
  margin: 0 auto 10px;
}
p {
  font-family: "Montserrat";
  font-weight: 700;
  color: var(--white);
  line-height: 1.4;
  font-size: 1.125rem;
  margin: 0 auto;
  max-width: 800px;
  margin-bottom: 30px;
  &.teal {
    color: var(--teal);
  }
}
.landing {
  h1,
  h2,
  p {
    padding: 0 50px;
  }
}
</style>